let $grid;
let loadedAllStories;

/**
 * If the user filtered the stories by their category, then the category key should be appended to the next page url.
 * */
function refineNextPageUrl() {
    if (activeCategoryPath) {
        let nextStoriesUrlSplit = nextPageUrl.split("?");
        let nextStoriesUrl = nextStoriesUrlSplit[0];
        let nextStoriesQueryString = nextStoriesUrlSplit[1];
        nextStoriesUrl += "/" + activeCategoryPath;
        nextPageUrl = nextStoriesUrl + "/" + activeCategoryPath + "?" + nextStoriesQueryString;
    }
}

function buildLoadPathForInfiniteScroll() {
    return nextPageUrl
      .replace(/(page=)[^\&]+/, "page=" + page)
      .replace(/(pageSize=)[^\&]+/, "pageSize=" + pageSize);
}

function handleInfiniteScrollLoadEvent(event, response) {
    page++;
    loadedAllStories = page >= lastPage;
    let $items = $(response);
    $items.imagesLoaded(function () {
        var gridInfiniteScroll = $grid.data('infiniteScroll');
        if(gridInfiniteScroll) {
            $grid.infiniteScroll('appendItems', $items)
                .masonry('appended', $items);

            if (loadedAllStories) {
                $grid.infiniteScroll('destroy');
                $('.page-load-status').hide();
            }
        }
    })
}

function initMasonryPlugin() {
    $grid.masonry({
        itemSelector: '.js-storyItem',
        columnWidth: '.grid__col-sizer',
        gutter: '.grid__gutter-sizer',
        percentPosition: true,
        stagger: 30,
        visibleStyle: {transform: 'translateY(0)', opacity: 1},
        hiddenStyle: {transform: 'translateY(100px)', opacity: 0},
    });

    setTimeout(() => {
        //listener usato da react per intercettare l'init di mansonry
        const event = new Event('masonryInitialized');
        document.dispatchEvent(event);
    })
}


function reloadItemsMasonry() {
    // Controlla che $grid sia definito e che Masonry sia inizializzato
    if ($grid && $grid.data('masonry')) {
        try {
            // Esegui solo il layout per riorganizzare gli elementi
            $grid.masonry('layout');
        } catch (error) {}
    }
}


function initInfiniteScrollPlugin() {

    $grid.infiniteScroll({
        path: buildLoadPathForInfiniteScroll,
        append: false,
        responseType: 'text',
        checkLastPage: true,
        scrollThreshold: 600,
        outlayer: $grid.data('masonry'),
        status: '.page-load-status',
        history: false,
        prefill: false
    });

    // attaches to load event
    $grid.on('load.infiniteScroll', handleInfiniteScrollLoadEvent);
}

function setHeightImages (){
    // get first image not double width and set images height
    if($('.js-storyItem:not(.stories-grid__item--double):first')) {
        let imgWidth = $('.js-storyItem:not(.stories-grid__item--double):first').find('.storyItem__image').css('width');
        $('.storyItem__image').css('height', imgWidth);
        $('.storyItem__image img').css('height', imgWidth);
    }
}

function showActiveCategory(keyframes, options){
    const $scrollBar = $('.navbarCategories__nav');
    let $currentEl = $('.navbarCategories__item.active');
    let paddingElement = parseFloat($currentEl.css('padding-left'));
    let offset = 0;

    if($currentEl) {
        //if is not first element add padding-left
        if ($currentEl.prev().hasClass('navbarCategories__item')) {
            offset += paddingElement;
        }

        while ($currentEl.prev().hasClass('navbarCategories__item')) {
            $currentEl = $currentEl.prev();
            offset += $currentEl.outerWidth();
        }
    }
    if($scrollBar) {
        $scrollBar.animate({scrollLeft: offset}, 300, 'swing');
    }
}

function initStoriesInfiniteScroll() {
    $grid = $('.grid');
    loadedAllStories = false;

    setHeightImages();
    refineNextPageUrl();
    initMasonryPlugin();
    initInfiniteScrollPlugin();
    showActiveCategory();
}

$(document).ready(initStoriesInfiniteScroll);
if (typeof(turnOffInfiniteScroll) === "undefined"){
    $(window).on('resize',initStoriesInfiniteScroll);
}







